import { extendTheme, baseTheme } from '@chakra-ui/react'

const theme = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: 'Montserrat, sans-serif',
    body: 'Montserrat, sans-serif',
  },
  colors: {
    black: '#131313',
    gray: {
      100: '#bcbcbc',
      200: '#a2a2a2',
      300: '#898989',
      400: '#6f6f6f',
      500: '#565656',
      600: '#3c3c3c',
      700: '#232323',
      800: '#090909',
      900: '#000000',
    },
    brand: {
      50: '#002400',
      100: '#003300',
      200: '#094100',
      300: '#1c5014',
      400: '#2f5e27',
      500: '#426d3b',
      600: '#557c4f',
      700: '#688a62',
      800: '#7b9976',
      900: '#8ea789',
    },
  },
  styles: {
    global: props => ({
      html: {
        backgroundColor: 'white',
      },
    }),
  },
}

export default extendTheme(theme)
export { baseTheme }
